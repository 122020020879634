import { http, HttpResponse } from "msw";
import db, { accounts, defaultMockAuthenticationToken } from "../db";
import { ErrorNotAuthorized, ErrorNotFound } from "./errors";
import { AccountUpdateRequest } from "app/services/account";
import { authorizationHeader } from "./common";

export const efficiencyForecastFetch = http.get("/v1/efficiency",
  async ({ request }) => {

    const header = request.headers.get(authorizationHeader);
    if (header !== `Bearer ${defaultMockAuthenticationToken}`) {
      return HttpResponse.json(ErrorNotAuthorized, { status: 401 });
    }


    let efficiencyForecast = {
      id: "0",
      week: 35,
      sign: 0,
      efficiency: {
        general: db.Efficient.findFirst({
          where: {
            group: {
              equals: "general"
            }
          }
        }),
        health: db.Efficient.findFirst({
          where: {
            group: {
              equals: "health"
            }
          }
        }),
        love: db.Efficient.findFirst({
          where: {
            group: {
              equals: "love"
            }
          }
        }),
        work: db.Efficient.findFirst({
          where: {
            group: {
              equals: "work"
            }
          }
        })
      }
    };

    return HttpResponse.json(efficiencyForecast, { status: 200 });
  });
import { http, HttpResponse } from "msw";
import { SessionRequest, SessionResponse } from "app/services/session";
import db, { defaultMockAuthenticationToken } from "../db";

export const createSession = http.post("/v1/session", async ({ request }) => {
  const data = (await request.json()) as SessionRequest;
  console.log(data);
  let account = db.Account.findFirst({
    where: {
      id: "",
    },
  });

  if (!account) {
    return HttpResponse.json({});
  }

  let session: SessionResponse = {
    token: defaultMockAuthenticationToken,
  };

  return HttpResponse.json(session);
});

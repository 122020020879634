import { ModelDefinitionValue } from "@mswjs/data/lib/glossary"
import { primaryKey } from "@mswjs/data"
import { Account } from "app/types/account";
import { Efficiency } from "../../app/types/efficiency";

export function defineModel<T>(
  // eslint-disable-next-line no-unused-vars
  generator: () => { [key in keyof T]: ModelDefinitionValue }
) {
  return generator()
}

export const EfficiencyModel = defineModel<Efficiency>(() => ({
  id: primaryKey(String),
  sign: Number,
  group: Number,
  percent: Number,
  summary: String,
  content: String,
}))

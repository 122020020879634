import { createEntityAdapter } from "@reduxjs/toolkit"
import { AccountResponse } from "app/services/account"
import { accountFetch, accountUpdate, accountAvatarUpdate } from "./handlers/account";
import { createSession } from "./handlers/session";
import { efficiencyForecastFetch } from "./handlers/efficiency";
import { compatibilityFetch } from "./handlers/compatibility";

// We're just going to use a simple in-memory store for both the counter and posts
// The entity adapter will handle modifications when triggered by the MSW handlers

// let count = 0
// let startingId = 3 // Just a silly counter for usage when adding new posts

const adapter = createEntityAdapter<AccountResponse>()

let state = adapter.getInitialState()
state = adapter.setAll(state, [])

export { state }

// Just use a random id for an auth token
// const token = nanoid()

export const handlers = [
  createSession,
  accountFetch, accountUpdate, accountAvatarUpdate,
  efficiencyForecastFetch, compatibilityFetch
];

import { retry } from "@reduxjs/toolkit/query/react"
import { Account, AccountBirthDay, AccountSettings } from "app/types/account"
import { api } from "./api"
import { EmptyRequest } from "./empty"
import { setAccount, setAccountBirthday, setAccountSettings } from "../../features/auth/authSlice";
import * as Sentry from "@sentry/react";

export type AccountResponse = {
  id: string,
  username: string,
  first_name: string,
  last_name: string,
  avatar: string,

  sign: number,
  birthday: AccountBirthdayResponse,
  settings: AccountSettingsResponse,

  access: string,
  created: number,
  updated: number,
}

export type AccountBirthdayResponse = {
  date: number,
  country: string,
  city: string,
  street: string,
  state: string,
  latitude: number,
  longitude: number,
}

export type AccountSettingsResponse = {
  language: string,
  timezone: string,
  report_weekly_time: string,
  report_weekly_wday: number,
}

export type AccountUpdateRequest = {
  username: string,
  first_name: string,
  last_name: string,
  sign: number,
}

export type AccountBirthdayUpdateRequest = {
  date: number,
  country: string,
  city: string,
  street: string,
  state: string,
  latitude: number,
  longitude: number,
}

export type AccountSettingsUpdateRequest = {
  language: string,
  timezone: string,
  report_weekly_time: string,
  report_weekly_wday: number,
}


export type AccountAvatarUploadRequest = {
  file: BinaryData
}

function getAccountFromResponse(src: AccountResponse): Account {
  return {
    ...src
  }
}

function getAccountSettingsFromResponse(src: AccountSettingsResponse): AccountSettings {
  return {
    ...src
  }
}

function getAccountBirthdayFromResponse(src: AccountBirthdayResponse): AccountBirthDay {
  return {
    ...src
  }
}

export const accountApi = api.injectEndpoints({
  endpoints: (build) => ({

    accountFetch: build.query<Account, EmptyRequest>({
      query: () => ({
        url: "v1/account",
        method: "GET",
      }),
      transformResponse: (response: AccountResponse): Account => {
        return getAccountFromResponse(response);
      },
      extraOptions: {
        backoff: () => {
          retry.fail({ fake: "error" })
        },
      },
    }),

    accountUpdate: build.mutation<Account, AccountUpdateRequest>({
      query: (account: AccountUpdateRequest) => ({
        url: "v1/account",
        method: "PUT",
        body: account,
      }),
      transformResponse: (response: AccountResponse): Account => {
        return getAccountFromResponse(response);
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setAccount(data));
        } catch (err) {
          console.error("Failed to update account", err);
          Sentry.captureMessage("Service: Failed to update account");
          Sentry.captureException(err);
        }
      },
      extraOptions: {
        backoff: () => {
          retry.fail({ fake: "error" })
        }
      },
    }),

    accountSettingsUpdate: build.mutation<AccountSettings, AccountSettingsUpdateRequest>({
      query: (account: AccountSettingsUpdateRequest) => ({
        url: "v1/account/settings",
        method: "PUT",
        body: account,
      }),
      transformResponse: (response: AccountSettingsResponse): AccountSettings => {
        return getAccountSettingsFromResponse(response);
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setAccountSettings(data));
        } catch (err) {
          console.error("Failed to update account", err);
          Sentry.captureMessage("Service: Failed to update account");
          Sentry.captureException(err);
        }
      },
      extraOptions: {
        backoff: () => {
          retry.fail({ fake: "error" })
        }
      },
    }),

    accountBirthdayUpdate: build.mutation<AccountBirthDay, AccountBirthdayUpdateRequest>({
      query: (account: AccountBirthdayUpdateRequest) => ({
        url: "v1/account/birthday",
        method: "PUT",
        body: account,
      }),
      transformResponse: (response: AccountBirthdayResponse): AccountBirthDay => {
        return getAccountBirthdayFromResponse(response);
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setAccountBirthday(data));
        } catch (err) {
          console.error("Failed to update account", err);
          Sentry.captureMessage("Service: Failed to update account");
          Sentry.captureException(err);
        }
      },
      extraOptions: {
        backoff: () => {
          retry.fail({ fake: "error" })
        }
      },
    }),

    accountAvatarUpdate: build.mutation<any, FormData>({
      query: (formData) => ({
        url: "v1/account/avatar",
        method: "POST",
        body: formData,
      }),
      transformResponse: (response: AccountResponse): Account => {
        return getAccountFromResponse(response);
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setAccount(data));
        } catch (err) {
          console.error("Failed to update avatar", err);
          Sentry.captureMessage("Service: Failed to update avatar");
          Sentry.captureException(err);
        }
      },
      extraOptions: {
        backoff: () => {
          retry.fail({ fake: "error" })
        }
      },
    }),
  }),
})

export const {
  useAccountFetchQuery,
  useLazyAccountFetchQuery,
  useAccountUpdateMutation,
  useAccountBirthdayUpdateMutation,
  useAccountSettingsUpdateMutation,
  useAccountAvatarUpdateMutation
} = accountApi

export const {
  endpoints: { accountFetch, accountUpdate, accountAvatarUpdate },
} = accountApi


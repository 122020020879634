export enum Sign {
  Aries = 1,
  Taurus,
  Gemini,
  Cancer,
  Leo,
  Virgo,
  Libra,
  Scorpio,
  Sagittarius,
  Capricorn,
  Aquarius,
  Pisces,
}

export const getSign = (sign: Sign): string => {
  switch (sign) {
    case Sign.Aries:
      return "aries";
    case Sign.Taurus:
      return "taurus";
    case Sign.Gemini:
      return "gemini";
    case Sign.Cancer:
      return "cancer";
    case Sign.Leo:
      return "leo";
    case Sign.Virgo:
      return "virgo";
    case Sign.Libra:
      return "libra";
    case Sign.Scorpio:
      return "scorpio";
    case Sign.Sagittarius:
      return "sagittarius";
    case Sign.Capricorn:
      return "capricorn";
    case Sign.Aquarius:
      return "aquarius";
    case Sign.Pisces:
      return "pisces";
    default:
      return "";
  }
};

// Новый метод для получения стихии знака зодиака
export const getElement = (sign: Sign): string => {
  switch (sign) {
    case Sign.Aries:
    case Sign.Leo:
    case Sign.Sagittarius:
      return "fire"; // Огонь

    case Sign.Taurus:
    case Sign.Virgo:
    case Sign.Capricorn:
      return "earth"; // Земля

    case Sign.Gemini:
    case Sign.Libra:
    case Sign.Aquarius:
      return "air"; // Воздух

    case Sign.Cancer:
    case Sign.Scorpio:
    case Sign.Pisces:
      return "water"; // Вода

    default:
      return "";
  }
};
export enum EfficiencyGroup {
	general = "general",
	health = "health",
	love = "love",
	work = "work",
}

export type EfficiencyForecast = {
  id: string;
  week: number;
  sign: number;
  efficiency: {
    general: Efficiency;
    health: Efficiency;
    love: Efficiency;
    work: Efficiency;
  }
};

export type Efficiency = {
	id: string;
	sign: number;
	group: EfficiencyGroup;
	percent: number;
	summary: string;
	content: string;
};

import { http, HttpResponse } from "msw";
import db, { accounts, defaultMockAuthenticationToken } from "../db";
import { ErrorNotAuthorized, ErrorNotFound } from "./errors";
import { AccountUpdateRequest } from "app/services/account";
import { authorizationHeader } from "./common";

export const accountFetch = http.get("/v1/account",
  async ({ request }) => {

    const header = request.headers.get(authorizationHeader);
    if (header !== `Bearer ${defaultMockAuthenticationToken}`) {
      return HttpResponse.json(ErrorNotAuthorized, { status: 401 });
    }

    let account = db.Account.findFirst({
      where: {
        id: {
          equals: accounts[0].id
        }
      }
    });

    if (!account) {
      return HttpResponse.json(ErrorNotFound, { status: 404 });
    }

    return HttpResponse.json(account, { status: 200 });
  });

export const accountUpdate = http.put("/v1/account",
  async ({ request }) => {

    const header = request.headers.get(authorizationHeader);
    if (header !== `Bearer ${defaultMockAuthenticationToken}`) {
      return HttpResponse.json(ErrorNotAuthorized, { status: 401 });
    }

    const payload = await request.json() as AccountUpdateRequest
    const account = db.Account.update({
      where: {
        id: {
          equals: accounts[0].id
        }
      },
      data: {
        ...payload
      }
    })
    return HttpResponse.json(account, { status: 200 });
  });

export const accountAvatarUpdate = http.post("/v1/account/avatar",
  async ({ request }) => {

    const header = request.headers.get(authorizationHeader);
    if (header !== `Bearer ${defaultMockAuthenticationToken}`) {
      return HttpResponse.json(ErrorNotAuthorized, { status: 401 });
    }

    let account = db.Account.update({
      where: {
        id: {
          equals: accounts[0].id
        }
      },
      data: {
        avatar: ""
      }
    });
    return HttpResponse.json(account, { status: 200 });
  });

import {useEffect} from "react";
import {Navigate, NavLink, useNavigate} from "react-router-dom";
import {useWebApp} from "app/telegram";
import {useAccount} from "hooks/useAccount";
import {useTranslation} from "react-i18next";
import {FeatureWelcomePath} from "../features/welcome/routes";
import {ScreenHelloPath} from "../features/welcome/screens/screenHello";
import {Sign, getSign, getElement} from "app/types/sign";
import {useTheme} from "../context/theme.provider";
import Card from "../widgets/components/card";
import EfficientCard, {EfficiencyListFeature} from "../features/efficiency/efficiency";
import {CompatibilityListFeature} from "../features/compatibility/compatibility";
import {HoroscopeForecastFeature} from "../features/horoscpoe/horoscope";

export const HomePage = () => {
  const {t} = useTranslation();

  const navigate = useNavigate();
  const {account} = useAccount();

  const {toggleElementTheme} = useTheme()

  const webApp = useWebApp();
  const startParam = webApp.initDataUnsafe.start_param;
  const sign = Sign.Leo; // Выберите знак

  useEffect(() => {
    if (startParam) {
      webApp.initDataUnsafe.start_param = "";
      const startapp = /(\w+)-(.*)/;
      const [, target, id] = startParam.match(startapp) || [];
      if (target === "horo" && id) return navigate(`horo/${id}/`);
    }
  }, []);

  useEffect(() => {
    console.log(getSign(sign));
    toggleElementTheme(getElement(sign))
  }, []);

  if (!account?.settings?.report_weekly_time) {
    //return <Navigate to={FeatureWelcomePath(ScreenHelloPath)} />;
  }

  if (startParam) {
    const startapp = /(\w+)-(.*)/;
    const [, target] = startParam.match(startapp) || [];
    if (!target) return <></>;
  }

  return (
    <div>
      <div className="w-full mb-10 constellation">
        <div className="w-full glow">

          <div className="w-full h-10 mt-10 text-center content-center">
            <img
              src={`/ico/constellation/${getSign(sign)}.svg`}
              className="h-16 w-16 mx-auto"
              alt={getSign(sign)}
            />
          </div>

          <div className="w-full h-14 mt-5 py-5 text-2xl text-center content-center">
            <h1 className="uppercase">{account.username}</h1>
            <h1 className="uppercase">{getSign(sign)}</h1>
          </div>

          <div className="w-full h-14 my-5 text-center content-center min-h-[320px]">
            <img
              src={`/img/constellation/${getSign(sign)}.svg`}
              className="h-60 w-60 mx-auto"
              alt="leo"
            />
          </div>
        </div>
      </div>
      <div className="px-5 mb-10">
        <HoroscopeForecastFeature />
      </div>

      <div className="px-5 mb-10 text-center">
        <p className="text-md">
          Upgrade to see more detailed horoscope
        </p>
        <button className="rounded-md mt-2 px-5 py-2 bg-tg-theme-button text-tg-theme-button-text">
          Upgrade
        </button>
      </div>

      <div className="px-5 mb-10">
        <EfficiencyListFeature/>
      </div>


      <div className="px-5 mb-10">
        <CompatibilityListFeature/>
      </div>
      <div className="px-5 mb-10">
        <Card title="Horoscope Settings">
          <div className="flex justify-between items-center text-gray-400 my-3">
            <p><i className={`text-gray-400 cursor-pointer fa fa-calendar mr-2`}/>Send day</p>
            <span>
               {t(`common.days.${account.settings.report_weekly_wday}`)} at {account.settings.report_weekly_time}
            </span>
          </div>
          <div className="flex justify-between items-center text-gray-400 my-3">
            <p>  <i className={`text-gray-400 cursor-pointer fa fa-comment-dots mr-2`}/>
              Detail</p>
            <span>

              {account.access}
            </span>
          </div>
        </Card>
      </div>

    </div>
  );
};
import { ModelDefinitionValue } from "@mswjs/data/lib/glossary"
import { primaryKey } from "@mswjs/data"
import { Account } from "app/types/account";

export function defineModel<T>(
  // eslint-disable-next-line no-unused-vars
  generator: () => { [key in keyof T]: ModelDefinitionValue }
) {
  return generator()
}

export const AccountModel = defineModel<Account>(() => ({
  id: primaryKey(String),
  username: String,

  first_name: String,
  last_name: String,

  avatar: String,

  sign: Number,
  birthday: {
    date: Number,
    country: String,
    city: String,
    street: String,
    state: String,
    latitude: Number,
    longitude: Number,
  },

  settings: {
    timezone: String,
    language: String,
    report_weekly_time: String,
    report_weekly_wday: Number,
  },

  access: String,
  updated: Number,
  created: Number,
}))

export const ErrorBadRequest = {
  "code": 400,
  "status": "Bad request",
  "message": "Bad request"
}

export const ErrorNotAuthorized = {
  "code": 401,
  "status": "Not Authorized",
  "message": "Not Authorized"
}

export const ErrorNotFound = {
  "code": 404,
  "status": "Not Found",
  "message": "Not Found"
}

export const ErrorMethodNotAllowed = {
  "code": 405,
  "status": "Method Not Allowed",
  "message": "Method Not Allowed"
}

export const ErrorInternalServerError = {
  "code": 500,
  "status": "Internal server error",
  "message": "Internal server error"
}
import { factory } from "@mswjs/data";

import { AccountModel } from "./models/account";
import { EfficiencyModel } from "./models/efficiency";
import { EfficiencyGroup } from "../app/types/efficiency";
import {CompatibilityModel} from "./models/compatibility";

export const defaultMockAuthenticationToken = "demo-token";

const db = factory({
  Account: AccountModel,
  Efficient: EfficiencyModel,
  Compatibility: CompatibilityModel
});

export default db;

// ===========================
// define accounts
// ===========================

export const accounts: any[] = [
  db.Account.create({
    id: "726c3f75-eb29-41c9-ae09-784bc0ecca4f",
    first_name: "Alexander",
    last_name: "Kaloshin",
    settings: {
      language: "en",
      report_weekly_time: "08:00",
      report_weekly_wday: 1,
    },
    access: "basic",
  }),
  db.Account.create({
    id: "c01d28e3-ed64-47eb-a8cd-a1279b5a02fb",
    first_name: "Edgar",
    last_name: "Sipki",
    settings: {
      language: "ru",
      report_weekly_time: "08:00",
      report_weekly_wday: 1,
    },
    access: "basic",
  }),
];


export const efficients: any[] = [
  db.Efficient.create({
    id: 1,
    sign: 0,
    group: EfficiencyGroup.general,
    percent: 45,
    summary: "You are lucky today",
    content: "Like a lot of people today, you have a deep need to take quick action -- though yours could probably stand to be thought out a bit more than anyone else's. Your good mental energy helps you in the end."
  }),

  db.Efficient.create({
    id: 2,
    sign: 0,
    group: EfficiencyGroup.health,
    percent: 80,
    summary: "You are lucky today",
    content: "Like a lot of people today, you have a deep need to take quick action -- though yours could probably stand to be thought out a bit more than anyone else's. Your good mental energy helps you in the end."
  }),

  db.Efficient.create({
    id: 3,
    sign: 0,
    group: EfficiencyGroup.love,
    percent: 80,
    summary: "You are lucky today",
    content: "Like a lot of people today, you have a deep need to take quick action -- though yours could probably stand to be thought out a bit more than anyone else's. Your good mental energy helps you in the end."
  }),

  db.Efficient.create({
    id: 4,
    sign: 0,
    group: EfficiencyGroup.work,
    percent: 80,
    summary: "You are lucky today",
    content: "Like a lot of people today, you have a deep need to take quick action -- though yours could probably stand to be thought out a bit more than anyone else's. Your good mental energy helps you in the end."
  })
]

export const compatibility: any[] = [
  db.Compatibility.create({
    id: 1,
    sign: 1,
    group: EfficiencyGroup.general,
  }),

  db.Compatibility.create({
    id: 3,
    sign: 3,
    group: EfficiencyGroup.love,
  }),

  db.Compatibility.create({
    id: 4,
    sign: 6,
    group: EfficiencyGroup.work,
  })
]
